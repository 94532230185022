<template>
  <v-container fluid>
  </v-container>
</template>

<script>


import jsPDF from 'jspdf'

export default {
  data: () => ({
    generalBalance: null,
    desserts: [],
    ingresosToday: null,
    margenSubtitle: null,
    egresosToday: null,
    saldoToday: null,
    listCashOperation: [],
    reportTitle: null,
    employee: 'ADRIANO PATRICIO QUEIROLO ',
    paymentDate: '16-02-2023 19:09:00',
    client: 'WALTER ENRIQUE IBARRA',
    location: null,
    description: 'Corresponde a 1 cuotas',
    amount: 6900,
    amountExpiringInterest: 0,
    amountVisitInterest: 0,
    documentnumber: '2023621639330',
    total: 6900,
    saldo: 34500,


  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },

  async mounted() {

    await this.generarReporte()

  },
  methods: {
    calcularPorcentajeInteres() {
      return Math.round((this.amountExpiringInterest) * 100 / parseFloat(this.amount))
    },

    async generarReporte() {
    //   const doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "px",
    //     //format: [4, 2],
    //     format: "A4",
    //     margin: { top: 20 },
    //   });
      const doc = new jsPDF('p', 'pt')

      const logo = require('../../assets/images/logos/efectivo-ya-png.png')

      const imgLogo = new Image()
      imgLogo.src = logo
      doc.addImage(imgLogo, 'PNG', 40, 40, 200, 80)
      doc.setFontSize(50)
      const titulo = 'Recibo'
      doc.text(titulo, doc.internal.pageSize.width * 0.4, 190, {
        aling: 'center',

      })

      doc.line(20, 140, 560, 140)

      doc.text('20232388341', doc.internal.pageSize.width * 0.3, 240, {
        aling: 'center',
      })

      // const lineaInicial = 70;

      //   const lineaInicialSombreado = 80;

      const espacioEntreLineas = 60

      const fechaCobro = this.paymentDate

      // doc.setFillColor(240, 240, 240)
      // doc.rect(
      //   doc.internal.pageSize.width * 0.05,
      //   5,
      //   900,
      //   13,
      //   'F',
      // )
      doc.setFontSize(38)
      doc.text(
        fechaCobro,
        doc.internal.pageSize.width * 0.5,
        35 + espacioEntreLineas * 1,
        {
          aling: 'center',

        },
      )

      // const cobrador = `Cobrador: ${this.employee}`
      // const client = `Cliente: ${this.client}`
      // const location = `Dirección: ${this.location}`
      // const description = `Detalle: ${this.description}`
      doc.setFontSize(50)
      const amount = `Monto: $ ${this.amount}`
      const amountExpiringInterest = `Interés ${this.calcularPorcentajeInteres()}% por Venc.: $  ${this.amountExpiringInterest}`
      const amountVisitInterest = `Rec. por visita: $ ${this.amountVisitInterest}`
      const total = `Total: $ ${this.total}`
      const saldo = `Saldo del crédito: $ ${this.saldo}`
      doc.setFontSize(30)
      doc.text(
        'Cobrador',
        doc.internal.pageSize.width * 0.055,
        250 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.line(doc.internal.pageSize.width * 0.055, 320, 200, 320)
      doc.setFontSize(37)
      doc.text(
        this.employee,
        doc.internal.pageSize.width * 0.055,
        302 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.setFontSize(30)
      doc.text(
        'Cliente',
        doc.internal.pageSize.width * 0.055,
        350 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.line(doc.internal.pageSize.width * 0.05, 420, 200, 420)
      doc.setFontSize(37)
      doc.text(
        this.client,
        doc.internal.pageSize.width * 0.055,
        402 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.setFontSize(30)
      doc.text(
        'Detalle',
        doc.internal.pageSize.width * 0.055,
        450 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.line(doc.internal.pageSize.width * 0.05, 520, 200, 520)
      doc.setFontSize(37)
      doc.text(
        this.description,
        doc.internal.pageSize.width * 0.055,
        502 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )

      doc.text(
        amount,
        doc.internal.pageSize.width * 0.055,
        570 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )
      doc.text(
        amountExpiringInterest,
        doc.internal.pageSize.width * 0.055,
        620 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )
      doc.text(
        amountVisitInterest,
        doc.internal.pageSize.width * 0.055,
        670 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )

      doc.text(
        total,
        doc.internal.pageSize.width * 0.055,
        720 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )
      doc.line(doc.internal.pageSize.width * 0.055, 795, 400, 795)
      doc.setFontSize(30)
      doc.text(
        saldo,
        doc.internal.pageSize.width * 0.055,
        770 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )

      doc.save(`Recibo ${this.client}.pdf`, false)
    },

   

  },
}
</script>
